import React from "react";

import { Button, Stack, Typography } from "@mui/material";

///The terms page
const Terms = ({ handleTermsAgreed }) => (
  <Stack spacing={1} sx={{ pt: 4 }}>
    <Typography variant="h5" sx={{ pb: 1 }}>
      Crown Downloads - Graphics Policy
    </Typography>
    <Typography variant="body2">
      All graphics included in this area of copyright Crown Products (Kent)
      Limited 2000. Images copied from the website should be acknowledged and
      captioned at all times, as Crown furniture. This should be achieved by
      inserting a caption immediately adjacent to the image or alternatively by
      using the 'ALT' tag facility provided within HTML code.
      <br />
      <br />
      At no time should an image depicting Crown furniture be included on a page
      in such a way as to suggest that the furniture is manufactured by any
      company other than Crown, or be presented in a manner that suggests the
      image represents another notice before legal action is initiated to
      rectify the situation.
      <br />
      <br />
      These images must not be doctored, or altered in such a way as to remove
      the crown watermark.
      <br />
      <br />
      If you accept ALL conditions written above, continue by clicking "I
      Agree". This will take you to a login area for you to access our graphics.
      If you do not, continue by clicking "I Do Not Agree" and you will be
      returned to the home page.
    </Typography>
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ pt: 8 }}
    >
      <Button variant="contained" color="success" onClick={handleTermsAgreed}>
        I Agree
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => (window.location = "https://www.crown-imperial.co.uk/")}
      >
        I Do Not Agree
      </Button>
    </Stack>
  </Stack>
);

export default Terms;
