import {
  getRequestWithToken,
  getRequest,
  postRequest,
  postRequestWithToken,
} from "../apiUtils";
import apiURL from "../apiBaseURL";

const baseUrl = apiURL() + "Auth/";

//login api
const LoginURL = baseUrl + "login";
export function login(body) {
  return postRequest(LoginURL, body);
}

//Is the token valid
const istokenvalidURL = baseUrl + "istokenvalid";
export function istokenvalid(jwt) {
  return getRequestWithToken(istokenvalidURL, jwt);
}

const RequestPasswordResetURL = baseUrl + "RequestPasswordReset";
export function requestPasswordReset(body) {
  return postRequest(RequestPasswordResetURL, body);
}

const GetUserInviteByUniqueIdentifierURL =
  baseUrl + "GetUserInviteByUniqueIdentifier/";
export function GetUserInviteByUniqueIdentifier(inviteIdentifier) {
  return getRequest(GetUserInviteByUniqueIdentifierURL + inviteIdentifier);
}

const CompleteRegistrationForInvitedAccountURL =
  baseUrl + "CompleteRegistrationForInvitedAccount";
export function CompleteRegistrationForInvitedAccount(body, jwt) {
  return postRequestWithToken(
    CompleteRegistrationForInvitedAccountURL,
    body,
    jwt
  );
}
