import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ImagesInformation = ({ level }) => {
  const navigate = useNavigate();
  return level === 0 ? (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Select a product group
      </Typography>
      <Typography variant="body1">
        Select a Product Group from below to view and download images of Crown
        Furniture.
      </Typography>
    </Stack>
  ) : (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Browse Image Library
      </Typography>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Typography variant="body1">
            There are two versions of the image. It is recommended the smaller
            72dpi image be used for websites, and the larger 300dpi image for
            printing. Click the button to download a copy of the size required.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/images")}
            size="small"
          >
            Change product group
          </Button>
        </Grid>
      </Grid>

      {/* <Grid container>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant="subtitle2">Internet Explorer</Typography>
            <ul>
              <li>
                <Typography variant="p">
                  Right-Click the required version (72dpi or 300dpi).
                </Typography>
              </li>
              <li>
                <Typography variant="p">Select 'Save Target As'</Typography>
              </li>
              <li>
                <Typography variant="p">
                  Browse to a location of your choice and click Save.
                </Typography>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant="subtitle2">
              Chrome / Mozilla Firefox
            </Typography>
            <ul>
              <li>
                <Typography variant="p">
                  Right-Click the required version (72dpi or 300dpi).
                </Typography>
              </li>
              <li>
                <Typography variant="p">Select 'Save Link As'</Typography>
              </li>
              <li>
                <Typography variant="p">
                  Browse to a location of your choice and click Save.
                </Typography>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid> */}
    </Stack>
  );
};

export default ImagesInformation;
