import { getRequestWithToken } from "../apiUtils";
import apiURL from "../apiBaseURL";

const baseUrl = apiURL() + "File/";

const GetFoldersAndFilesURL = baseUrl + "GetFoldersAndFiles";
export function GetFoldersAndFiles(jwtToken) {
  return getRequestWithToken(GetFoldersAndFilesURL, jwtToken);
}

const GetFileURIURL = baseUrl + "GetFileURI?filename=";
export function GetFileURI(jwtToken, fileName) {
  return getRequestWithToken(GetFileURIURL + fileName, jwtToken);
}

const GenerateSASTokenURL = baseUrl + "GenerateSASToken";
export function GenerateSASToken(jwtToken) {
  return getRequestWithToken(GenerateSASTokenURL, jwtToken);
}
