import React, { useContext, useEffect, useCallback, useState } from "react";

import Cookie from "js-cookie";
import UserContext from "../../../context/UserContext";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import * as fileApi from "../../../api/File/fileApi";

import { AuthorisedLayout } from "../../layouts";
import { Alert, Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DisplayFilesAndFolders } from "./components";

const Home = () => {
  const jwtToken = Cookie.get("jwtToken");
  const {
    filesAndFolders: contextFilesAndFolders,
    currentDirectory: contextDirectory,
  } = useContext(UserContext);
  const [filesAndFolders, setFilesAndFolders] = contextFilesAndFolders;
  const [currentDirectory, setCurrentDirectory] = contextDirectory;
  const { type, catalogue } = useParams();

  const [loading, setLoading] = useState(true);

  const initialLoad = useCallback(async () => {
    if (filesAndFolders === null && loading) {
      setLoading(true);
      const response = await fileApi.GetFoldersAndFiles(jwtToken);
      if (
        response &&
        response.statusCode === 200 &&
        response.data &&
        Array.isArray(response.data)
      ) {
        setFilesAndFolders([...response.data]);
      } else {
        toast.error("Unable to retrieve files and folders");
      }
    }
    setLoading(false);
  }, [filesAndFolders]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      initialLoad();
    }
    return () => (mounted = false);
  }, [initialLoad]);

  //passed in the params set the current directory
  useEffect(() => {
    if (type && catalogue) {
      setCurrentDirectory(`${type}/${catalogue}`);
    } else if (type) {
      setCurrentDirectory(`${type}`);
    } else {
      setCurrentDirectory("images");
    }
  }, [type, catalogue]);

  return (
    <AuthorisedLayout alignItems="flex-start" justify="flex-start">
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack spacing={2} alignItems="center">
            <CircularProgress size={25} />
            <Typography variant="h6">Loading...</Typography>
          </Stack>
        </Box>
      ) : filesAndFolders ? (
        <DisplayFilesAndFolders />
      ) : (
        <Alert severity="info">Unable to load images and videos.</Alert>
      )}
    </AuthorisedLayout>
  );
};

export default Home;
