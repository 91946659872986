import React, { useEffect, useCallback, useState, useContext } from "react";

import Cookie from "js-cookie";
import { ToastContainer, toast } from "react-toastify";

import * as authApi from "../../../api/Auth/authApi";
import { GenerateSASToken } from "../../../api/File/fileApi";

import { AuthRouting, UnAuthRouting } from "../../Routing";
import { createTheme, ThemeProvider } from "@mui/material";

import { Loader } from "../";

import "react-toastify/dist/ReactToastify.min.css";
import UserContext from "../../../context/UserContext";
import { jwtDecode } from "jwt-decode";
import { redirect } from "react-router-dom";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [authorised, setAuthorised] = useState(false);
  const { user: contextUser, accessKey: contextAccess } =
    useContext(UserContext);
  const [user, setUser] = contextUser;
  const [accessKey, setAccessKey] = contextAccess;

  //check whether the current token is valid
  const jwtLoad = useCallback(async () => {
    setLoading(true);
    if (Cookie.get("jwtToken") != null && Cookie.get("jwtToken").length > 0) {
      try {
        const response = await authApi.istokenvalid(Cookie.get("jwtToken"));
        if (response) {
          await loadUser();
          //get the access token
          await handleGetAccessToken();

          //load the access token every 15 minutes
          setInterval(async () => {
            await handleGetAccessToken();
          }, 900000);
          setAuthorised(true);
        } else {
          logOutUser();
        }
      } catch (error) {
        toast.error("We are experiencing technical difficulties");
        logOutUser();
      }
    }
    setLoading(false);
  }, []);

  const handleGetAccessToken = async () => {
    const response = await GenerateSASToken(Cookie.get("jwtToken"));
    if (response && response.statusCode === 200 && response.data) {
      setAccessKey("?" + response.data);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      jwtLoad();
    }
    return () => (mounted = false);
  }, [jwtLoad]);

  ///log out the user if their token is no longer valid
  const logOutUser = async () => {
    Cookie.remove("jwtToken");
    localStorage.clear();
    //remove all information to do with the user
    setAuthorised(false);
    redirect("/");
    window.location.reload(false);
    setLoading(false);
  };

  const loadUser = async () => {
    let jwt = Cookie.get("jwtToken");

    //send api request here to validate the stored token
    let currentUser = { ...user };
    let decoded = jwtDecode(jwt);
    currentUser["name"] =
      decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    currentUser["email"] =
      decoded[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
      ];
    currentUser["userIdentifier"] =
      decoded[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ];
    currentUser["accountIdentifier"] =
      decoded[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid"
      ];
    currentUser["role"] =
      decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    currentUser["manufacturers"] =
      decoded[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/primarygroupsid"
      ];
    currentUser.isAuthenticated = true;
    setUser(currentUser);
  };

  //create the material ui theme
  const mainTheme = createTheme({
    spacing: 8,
    palette: {
      primary: {
        main: "#004380",
      },
      secondary: {
        main: "#d7a319",
      },
      //   error: {
      //     main: errorColour,
      //   },
      //   warning: {
      //     main: warningColour,
      //   },
      //   info: {
      //     main: infoColour,
      //   },
      //   success: {
      //     main: successColour,
      //   },
      //   sidebar: {
      //     background: "#242B45",
      //     hover: "rgba(255, 255, 255, 0.08)",
      //     text: "#F1FFE1",
      //   },
      //   background: {
      //     main: backgroundColour,
      //   },
      //   colours: {
      //     red: "#ba2a3f",
      //     orange: "#d55d40",
      //     yellow: "#ead172",
      //     green: "#358e5b",
      //     grey: greyColour,
      //     darkGrey: darkGreyColour,
      //   },
    },
    typography: {
      fontFamily: ["quicksand", "Arial"].join(","),
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            elevation: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: 16,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
        // defaultProps: {
        //   size: "small",
        // },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
        // defaultProps: {
        //   size: "small",
        // },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
        // defaultProps: {
        //   size: "small",
        // },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={mainTheme}>
        <ToastContainer
          autoClose={4000}
          hideProgressBar
          position="top-center"
        />
        {loading ? (
          <Loader />
        ) : authorised ? (
          <AuthRouting />
        ) : (
          <UnAuthRouting />
        )}
      </ThemeProvider>
    </>
  );
};

export default App;
