import React, { useState } from "react";

import Cookie from "js-cookie";
import { toast } from "react-toastify";

import * as AuthApi from "../../../../api/Auth/authApi";

import { Terms } from "./components";
import { UnAuthorisedLayout } from "../../../layouts";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  Box,
  TextField,
  Typography,
} from "@mui/material";

import CrownLogo from "../../../../assets/images/crown-imperial-logo.png";

//The login page
const Login = () => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [loginDetails, setLoginDetails] = useState({
    Email: "",
    Password: "",
  });

  //handle the terms being agreed
  const handleTermsAgreed = () => setTermsAgreed(true);

  function handleChange(event) {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value.trim(),
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    submitLogin(loginDetails);
  }

  const submitLogin = async (loginDetails) => {
    setLoading(true);
    if (loginDetails.Email === "" || loginDetails.Password === "") {
      toast.error("Please complete the form");
      setLoading(false);
      setErrorMessage(null);
    } else {
      try {
        let loginDetailsCopy = { ...loginDetails };
        //base 64 encode the password
        loginDetailsCopy.Password = btoa(loginDetailsCopy.Password);
        const response = await AuthApi.login(loginDetailsCopy);
        if (response.statusCode === 200) {
          Cookie.set("jwtToken", response.data.result, { expires: 1 });
          localStorage.setItem("directory", "");
          setErrorMessage(null);
          window.location.reload();
        } else {
          setLoading(false);
          setErrorMessage(response.message);
        }
      } catch (Error) {
        toast.error("We are experiencing technical difficulties");
      }
    }
  };

  return (
    <UnAuthorisedLayout alignItems="flex-start" justify="flex-start">
      {!termsAgreed ? (
        <Terms handleTermsAgreed={handleTermsAgreed} />
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 4 }}
        >
          <Grid item xs={12} md={6} lg={5}>
            <Card sx={{ padding: 2 }}>
              <CardContent>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={CrownLogo} alt="Crown logo" />

                  <Typography
                    variant="h5"
                    style={{ margin: `${1} 0`, fontWeight: 500 }}
                  >
                    Crown Downloads
                  </Typography>
                </Box>
              </CardContent>
              <CardContent>
                {errorMessage ? (
                  <Alert severity="error" sx={{ marginBottom: 2 }}>
                    {errorMessage}
                  </Alert>
                ) : null}
                {loading ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                      <FormControl fullWidth>
                        <TextField
                          id="email"
                          type="email"
                          variant="outlined"
                          onChange={handleChange}
                          name="Email"
                          label="Email"
                          placeholder="Email"
                          fullWidth
                          value={loginDetails.Email}
                          sx={{ marginBottom: 2 }}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          id="password"
                          type="password"
                          variant="outlined"
                          onChange={handleChange}
                          name="Password"
                          label="Password"
                          placeholder="Password"
                          fullWidth
                          value={loginDetails.Password}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <Typography
                        variant="body1"
                        color="primary"
                        sx={{ mt: 2 }}
                      >
                        <span style={{ fontWeight: "bold" }}>PLEASE NOTE</span>:
                        By logging into this area you are{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          agreeing
                        </span>{" "}
                        to our{" "}
                        <Typography
                          display="inline"
                          sx={(style) => ({
                            "&:hover": {
                              textDecoration: "underline",
                              cursor: "pointer",
                            },
                            color: style.palette.secondary.dark,
                          })}
                          onClick={() => setTermsAgreed(false)}
                        >
                          Terms and Conditions
                        </Typography>{" "}
                        of use.
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "1em",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Login
                        </Button>
                      </div>
                    </form>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </UnAuthorisedLayout>
  );
};

export default Login;
