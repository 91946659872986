import Cookie from "js-cookie";
import { toast } from "react-toastify";

export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 401) {
    console.log("logged out at apiUtils handleResponse");
    LogoutUser();
  }
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error(response);
}

// In a real app, would likely call an error logging service.
function handleError(error) {
  // debugger;
  // eslint-disable-next-line no-console
  if (error.message === "401") {
    // Cookie.remove("jwtToken");
    // // ///used to clear the url and reload the page
    // window.history.entries = [];
    // window.history.index = -1;
    // window.history.replaceState(null, "", "/");
    // window.location.reload(true);

    console.log("logged out at apiUtils handleError");
    LogoutUser();
    return "401";
  } else {
    //display error occured
    toast.error("An error occured making this request: " + error.message);
    //return blank data
    return { data: "" };
  }
}

//creates a get request
export function getRequest(URL) {
  let resultObj = {};
  let statusCode = "";
  return fetch(URL, {
    mode: "cors",
  })
    .then((response) => {
      statusCode = response.status;
      if (!response.ok && response.status !== 422)
        throw new Error(response.status);
      if (response.status === 401) throw new Error(response);
      if (response.status === 204) return "";
      return response.json();
    })
    .then((dataResult) => {
      resultObj = dataResult;
      if (resultObj.message != undefined) {
        resultObj.statusCode = statusCode;
      }

      return resultObj;
    })
    .catch(handleError);
}

//creates a get request with bearer token
export function getRequestWithToken(URL, jwt) {
  let resultObj = {};
  let statusCode = "";
  return fetch(URL, {
    mode: "cors",
    headers: new Headers({
      Authorization: "Bearer " + jwt,
    }),
  })
    .then((response) => {
      statusCode = response.status;
      if (!response.ok && response.status !== 422)
        // 422 = Unprocessed. We don't need to throw error.
        throw new Error(response.status);
      if (response.status === 401) throw new Error(response);
      if (response.status === 204) return "";
      return response.json();
    })
    .then((dataResult) => {
      //      console.log(dataResult);
      resultObj = dataResult;
      if (resultObj.message != undefined) {
        resultObj.statusCode = statusCode;
      }

      return resultObj;
    })
    .catch(handleError);
}

//creates a get request with a body
export function getRequestWithTokenAndBody(URL, body, jwt) {
  let resultObj = {};
  let statusCode = "";
  return fetch(URL, {
    // mode: "cors",
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + jwt,
      "content-type": "application/json; charset=utf-8",
    }),
    body: JSON.stringify(body),
  })
    .then((response) => {
      statusCode = response.status;
      if (!response.ok && response.status !== 422)
        throw new Error(response.status);
      if (response.status === 401) throw new Error(response);
      //if (response.status === 204) return "";
      return response.json();
    })
    .then((dataResult) => {
      //      console.log(dataResult);
      resultObj = dataResult;
      if (resultObj.message != undefined) {
        resultObj.statusCode = statusCode;
      }

      return resultObj;
    })
    .catch(handleError);
}

//creates a get request
export function getRequestTopOne(URL) {
  return fetch(URL, { mode: "cors" })
    .then((response) => {
      if (!response.ok && response.status !== 422)
        throw new Error(response.status);
      if (response.status === 401) throw new Error(response);
      if (response.status === 204) return "";
      return response.json();
    })
    .then((result) => {
      return result[0];
    })
    .catch(handleError);
}
//creates a post request
export function postRequest(URL, body) {
  let resultObj = {};
  let statusCode = "";
  return fetch(URL, {
    // mode: "cors",
    method: "POST",
    headers: { "content-type": "application/json; charset=utf-8" },
    body: JSON.stringify(body),
  })
    .then((response) => {
      statusCode = response.status;
      //if (response.status === 204) return "";
      return response.json();
    })
    .then((dataResult) => {
      //      console.log(dataResult);
      resultObj = dataResult;
      if (resultObj.message != undefined) {
        resultObj.statusCode = statusCode;
      }

      return resultObj;
    })
    .catch(handleError);
}

//creates a post request with the use of token
export function postRequestWithToken(URL, body, jwt) {
  let resultObj = {};
  let statusCode = "";
  return fetch(URL, {
    // mode: "cors",
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + jwt,
      "content-type": "application/json; charset=utf-8",
    }),
    body: JSON.stringify(body),
  })
    .then((response) => {
      statusCode = response.status;
      if (!response.ok && response.status !== 422)
        throw new Error(response.status);
      if (response.status === 401) throw new Error(response);
      //if (response.status === 204) return "";
      return response.json();
    })
    .then((dataResult) => {
      //      console.log(dataResult);
      resultObj = dataResult;
      if (resultObj.message != undefined) {
        resultObj.statusCode = statusCode;
      }

      return resultObj;
    })
    .catch(handleError);
}

//creates a put request
export function putRequestWithToken(URL, body, jwt) {
  let resultObj = {};
  let statusCode = "";
  return fetch(URL, {
    mode: "cors",
    method: "PUT",
    headers: new Headers({
      Authorization: "Bearer " + jwt,
      "content-type": "application/json; charset=utf-8",
    }),
    body: JSON.stringify(body),
  })
    .then((response) => {
      statusCode = response.status;
      if (!response.ok && response.status !== 422)
        throw new Error(response.status);
      if (response.status === 401) throw new Error(response);
      if (response.status === 204) return "";
      return response.json();
    })
    .then((dataResult) => {
      //      console.log(dataResult);
      resultObj = dataResult;
      if (resultObj.message != undefined) {
        resultObj.statusCode = statusCode;
      }

      return resultObj;
    })
    .catch(handleError);
}

//creates a delete request
export function deleteRequest(URL) {
  let resultObj = {};
  let statusCode = "";
  return fetch(URL, {
    mode: "cors",
    method: "DELETE",
  })
    .then((response) => {
      statusCode = response.status;
      if (!response.ok && response.status !== 422)
        throw new Error(response.status);
      if (response.status === 401) throw new Error(response);
      if (response.status === 204) return "";
      return response.json();
    })
    .then((dataResult) => {
      //      console.log(dataResult);
      resultObj = dataResult;
      if (resultObj.message != undefined) {
        resultObj.statusCode = statusCode;
      }

      return resultObj;
    })
    .catch(handleError);
}

//log out the user when a 401 response is received
//have removed the context parts as it was breaking the app in shared
function LogoutUser() {
  // const userContext = useContext(UserContext);
  // let currentUser = userContext.state.user;
  if (typeof document != "undefined") {
    Cookie.remove("jwtToken");
    window.history.entries = [];
    window.history.index = -1;
    window.history.replaceState(null, "", "/");
    window.location.reload(true);
  } else if (
    typeof navigator != "undefined" &&
    navigator.product == "ReactNative"
  ) {
    console.log("");
  }
}
