import React, { useCallback, useContext, useEffect, useState } from "react";

import Cookie from "js-cookie";
import * as fileApi from "../../../../../api/File/fileApi";

import UserContext from "../../../../../context/UserContext";
import { useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Loader } from "../../../../components";
import { Folder, InsertDriveFile } from "@mui/icons-material";
import VideosInformation from "./VideosInformation";
import ImagesInformation from "./ImagesInformation";
import { toast } from "react-toastify";

const DisplayFilesAndFolders = () => {
  const jwtToken = Cookie.get("jwtToken");
  const FOLDER = "FOLDER";
  const FILE = "FILE";
  const IMAGES = "images";
  const VIDEOS = "videos";
  const SCREENSAVER_FILE_LOCATION = "files/crown_screen_saver_instructions.pdf";
  const navigate = useNavigate();
  const { type, catalogue } = useParams();

  const {
    filesAndFolders: contextFilesAndFolders,
    currentDirectory: contextDirectory,
    accessKey: contextAccessKey,
  } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [level, setLevel] = useState(0);
  const [currentDirectory, setCurrentDirectory] = contextDirectory;
  const [filesAndFolders] = contextFilesAndFolders;
  const [directoryFilesAndFolders, setDirectoryFilesAndFolders] =
    useState(null);
  const [accessKey] = contextAccessKey; //used to access multiple blobs at once.

  useEffect(() => {
    if (type) {
      if (type === "videos") {
        setTabIndex(1);
      } else {
        setTabIndex(0);
      }
    }

    if (type && catalogue) {
      setLevel(1);
      setCurrentDirectory(`${type}/${catalogue}`);
    } else if (type && !catalogue) {
      setLevel(0);
      setCurrentDirectory(`${type}`);
    } else {
      navigate("/images");
    }
  }, [type, catalogue]);

  useEffect(() => {
    if (filesAndFolders) {
      if (currentDirectory) {
        let currentDirectoryClone = currentDirectory;
        //are we at the top, or one in?
        let levelLoc = characterCount(currentDirectoryClone, "/");

        if (
          currentDirectoryClone.charAt(currentDirectoryClone.length - 1) !== "/"
        ) {
          currentDirectoryClone = currentDirectory + "/";
        }
        setLevel(levelLoc);

        setDirectoryFilesAndFolders(
          [...filesAndFolders].filter(
            (i) =>
              i.name.includes(currentDirectoryClone) &&
              characterCount(i.name, "/") <= level + 1
          )
        );
      }
    }
    setLoading(false);
  }, [currentDirectory, filesAndFolders]);

  const handleTabChange = (e, newValue) => {
    setTabIndex(newValue);
    //setCurrentDirectory(e.target.innerText.toLowerCase());
    navigate(`/${e.target.innerText.toLowerCase()}`);
    //setLevel(0);
  };

  // we use character counts to work out if we are at the right place in a directory
  const characterCount = (string, character) => {
    return string.split(character).length - 1;
  };

  //check if a different name needs to be displayed
  const checkForTextValueOverride = (textValue) => {
    switch (textValue) {
      case "imperial-bedrooms":
        return "Bedrooms with LifeSpace";
      case "imperial-kitchens":
        return "Imperial Kitchens";
      case "lifestyle-kitchens":
        return "Lifestyle Kitchens";
      case "spaceworks":
        return "Spaceworks";
      default:
        return textValue;
    }
  };

  const handleFileDownload = async (fileName, version) => {
    toast.info("File requested...");
    const response = await fileApi.GenerateSASToken(jwtToken);
    if (response.statusCode === 200 && response.data) {
      const url = `${process.env.REACT_APP_BLOB_URL}${currentDirectory}${
        version != null ? "/" + version : ""
      }/${fileName}?${response.data}`;
      fetch(url)
        .then((resp) =>
          resp.status === 200
            ? resp.blob()
            : Promise.reject("something went wrong")
        )
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          toast.success("File downloaded");
        })
        .catch(() => alert("Unable to access file"));
    } else {
      toast.error("Unable to access files");
    }
  };

  const ShowFolders = () => {
    const handleClick = (name) => {
      navigate(`/${name}`);
    };

    return directoryFilesAndFolders &&
      directoryFilesAndFolders.filter((i) => i.type === FOLDER).length > 0
      ? directoryFilesAndFolders
          .filter((i) => i.type === FOLDER)
          .map((folder) =>
            folder.name.replace("/", "").split(currentDirectory).length > 1 ? (
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => handleClick(folder.name)}
                >
                  <CardMedia
                    sx={{ height: 200, width: 300 }}
                    image={`${process.env.REACT_APP_BLOB_URL}${folder.name}.jpg${accessKey}`}
                  />
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      sx={{ fontWeight: "bold" }}
                    >{`${checkForTextValueOverride(
                      folder.name.replace("/", "").split(currentDirectory)[1]
                    )}`}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : null
          )
      : null;
  };

  const FileItem = ({ file }) => {
    const [downloading, setDownloading] = useState(false);

    const handle72dpi = async () => {
      setDownloading(true);
      await handleFileDownload(
        file.name.split(currentDirectory + "/")[1],
        "72dpi"
      );
      setDownloading(false);
    };

    const handle300dpi = async () => {
      setDownloading(true);
      await handleFileDownload(
        file.name.split(currentDirectory + "/")[1],
        "300dpi"
      );
      setDownloading(false);
    };

    const handleVideoDownload = async () => {
      setDownloading(true);
      await handleFileDownload(
        file.name.split(currentDirectory + "/")[1],
        null
      );
      setDownloading(false);
    };

    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardHeader
            sx={(sx) => ({
              backgroundColor: sx.palette.primary.light,
              width: "100%",
            })}
            title={
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#FFF",
                }}
              >
                {file.name
                  .split(currentDirectory + "/")[1]
                  .replace(".jpg", "")
                  .replace(".mp4", "")}
              </Typography>
            }
          />
          {file.name.includes(".mp4") ? (
            <Box sx={{ padding: 1 }}>
              <video width="470" height="255" controls>
                <source
                  src={`${process.env.REACT_APP_BLOB_URL}${file.name}${accessKey}`}
                  type="video/mp4"
                />
              </video>
            </Box>
          ) : (
            <CardMedia
              sx={{
                height: "100px",
                width: "150px",
                backgroundSize: "auto",
                padding: 2,
              }}
              image={`${process.env.REACT_APP_BLOB_URL}${file.name}${accessKey}`}
            />
          )}
          <CardActions
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: 3,
            }}
          >
            {downloading ? (
              <CircularProgress size={20} />
            ) : file.name.includes(".mp4") ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleVideoDownload}
              >
                Download
              </Button>
            ) : (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handle72dpi}
                >
                  72dpi
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handle300dpi}
                >
                  300dpi
                </Button>
              </Stack>
            )}
          </CardActions>
        </Card>
      </Grid>
    );
  };

  const ShowFiles = () => {
    return directoryFilesAndFolders &&
      directoryFilesAndFolders.filter(
        (i) => i.type === FILE && !i.name.includes(".txt")
      ).length > 0
      ? directoryFilesAndFolders
          .filter((i) => i.type === FILE && !i.name.includes(".txt"))
          .map((file) =>
            file.name.split(currentDirectory + "/").length > 1 ? (
              <FileItem file={file} />
            ) : null
          )
      : null;
  };

  return loading || !accessKey ? (
    <Loader />
  ) : (
    <Stack spacing={2}>
      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <Tabs value={tabIndex} onChange={handleTabChange} fullWidth>
            <Tab label="Images" />
            <Tab label="Videos" />
          </Tabs>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: 4 }}>
          {tabIndex === 0 ? (
            <ImagesInformation level={level} />
          ) : (
            VideosInformation()
          )}
        </Grid>
        {directoryFilesAndFolders && directoryFilesAndFolders.length > 0 ? (
          <>
            {level === 0 ? (
              <Grid item xs={12} container spacing={2}>
                <ShowFolders />
              </Grid>
            ) : null}
            <Grid item xs={12} container spacing={2}>
              {(level !== 0 && tabIndex === 0) || tabIndex === 1 ? (
                <ShowFiles />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {level === 0 && tabIndex === 0 ? (
                <Stack spacing={2} sx={{ marginTop: 4 }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Crown Furniture Screen Saver
                  </Typography>
                  <Typography variant="body1">
                    To create a Screen Saver of Crown furniture images, take a
                    look at these instructions that will have Crown images
                    revolving in your showroom in just 5 minutes!{" "}
                    <a
                      href={`${process.env.REACT_APP_BLOB_URL}${SCREENSAVER_FILE_LOCATION}${accessKey}`}
                      target="_blank"
                    >
                      Click here
                    </a>{" "}
                    to download the instructions.
                  </Typography>
                </Stack>
              ) : null}
            </Grid>
          </>
        ) : (
          <Alert severity="info">Unable to read directory</Alert>
        )}
      </Grid>
    </Stack>
  );
};

export default DisplayFilesAndFolders;
