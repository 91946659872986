import React, { useContext } from "react";

import { isMobile } from "react-device-detect";
import UserContext from "../../../context/UserContext";
import Cookie from "js-cookie";

import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Stack,
} from "@mui/material";

import { Logout } from "@mui/icons-material";

//this is a centered layout for when an object only needs to be in the center i.e a login
const AuthorisedLayout = ({
  alignItems = "center",
  justify = "center",
  children,
}) => {
  const { user: contextUser } = useContext(UserContext);
  const [user] = contextUser;

  const logout = () => {
    localStorage.clear();
    Cookie.remove("jwtToken");
    window.location.reload(false);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems={alignItems}
        justify={justify}
        overflow="auto"
      >
        <AppBar position="fixed" color="primary" enableColorOnDark>
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ p: 1, fontWeight: "bold" }}>
                Crown Downloads
              </Typography>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography gutterBottom={false} variant="subtitle1">
                Welcome {user.name}
              </Typography>
              <IconButton color="secondary" onClick={logout}>
                <Logout />
              </IconButton>
            </Stack>
          </Toolbar>
        </AppBar>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { justify },
            alignItems: { alignItems },
            overflow: "auto",
            marginTop: 8,
            paddingBottom: 16,
          }}
        >
          {children}
        </Container>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            p: 1,
            width: "100%",
            backgroundColor: "#f8f8f8",
            borderTop: "1px solid #e7e7e7",
          }}
        >
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            Crown Imperial is manufactured by Crown Products (Kent) Ltd.
            Copyright 2001-{`${new Date().getFullYear()}`}, Crown Products
            (Kent) Ltd. All Rights Reserved.
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default AuthorisedLayout;
