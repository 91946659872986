import React, { useEffect, useState } from "react";

import UserContext from "./UserContext";

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    accountIdentifier: "",
    userIdentifier: "",
    isAuthenticated: false,
    role: "",
    manufacturers: "ALL",
    accountType: null,
  });
  const [filesAndFolders, setFilesAndFolders] = useState(null);
  const [currentDirectory, setCurrentDirectory] = useState(""); //default to images
  const [accessKey, setAccessKey] = useState(null);

  //if the directory gets updated store it locally
  useEffect(() => {
    localStorage.setItem("directory", currentDirectory);
  }, [currentDirectory]);

  return (
    <UserContext.Provider
      value={{
        user: [user, setUser],
        filesAndFolders: [filesAndFolders, setFilesAndFolders],
        currentDirectory: [currentDirectory, setCurrentDirectory],
        accessKey: [accessKey, setAccessKey],
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
