import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

const VideosInformation = () => (
  <Stack spacing={1}>
    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
      Browse Video Library
    </Typography>
    <Typography variant="body1">
      To view the Crown Imperial YouTube channel please:{" "}
      <a href="https://www.youtube.com/@crown-imperial" target="_blank">
        Click here
      </a>
      .
    </Typography>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography variant="subtitle2">
            To download a video click the download button.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Stack>
);

export default VideosInformation;
