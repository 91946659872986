import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./views/Home";
import Login from "./views/Public/Login";
import CompleteUserSignup from "./views/Public/CompleteUserInvite";

export const AuthRouting = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Home />}></Route>
      <Route path="/:type/:catalogue" exact element={<Home />}></Route>
      <Route path="/:type" exact element={<Home />}></Route>
    </Routes>
  );
};

export const UnAuthRouting = () => {
  return (
    <Routes>
      <Route path="/CompleteUserInvite" element={<CompleteUserSignup />} />
      <Route path="*" element={<Login />}></Route>
    </Routes>
  );
};
