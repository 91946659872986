import React, { useCallback, useEffect, useState } from "react";

//packages
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetURLParameter } from "../../../../utils/urlUtils";
import PasswordStrengthBar from "react-password-strength-bar";
import Cookie from "js-cookie";
//api
import * as AuthApi from "../../../../api/Auth/authApi";
//components
import { UnAuthorisedLayout } from "../../../layouts";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

//logos
import CrownLogo from "../../../../assets/images/crown-imperial-logo.png";
import { accountStatuses } from "../../../../lookups/lookupConstants";

//complete signup page
const CompleteUserSignup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [signupDetails, setSignupDetails] = useState({
    email: "",
    firstName: "",
    lastname: "",
    password: "",
    phone: "",
  });
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [invite, setInvite] = useState({});
  const [account, setAccount] = useState({});

  //get the param of the user identifier
  const [identifierParam] = useState(
    atob(GetURLParameter("userinviteidentifier"))
  );

  const initialLoad = useCallback(async () => {
    //wait till api is no longer loading
    if (identifierParam) {
      const response = await AuthApi.GetUserInviteByUniqueIdentifier(
        identifierParam
      );
      if (response && response.data) {
        const { data } = response;
        //save user to state
        setInvite(data);
        setAccount(data.account);
      } else {
        toast.error("Invalid Request");
        navigate("/");
      }
    }
  }, [identifierParam]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      initialLoad();
    }
    return () => (mounted = false);
  }, [initialLoad]);

  function handleChange(event) {
    setSignupDetails({
      ...signupDetails,
      [event.target.name]: event.target.value.trim(),
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleRegister();
  }

  const handleRegister = async () => {
    setLoading(true);
    if (confirmPassword !== signupDetails.password) {
      toast.error("The two passwords do not match.");
      setLoading(false);
    } else {
      //make the model to send
      const registerUser = { ...signupDetails };
      registerUser.password = btoa(registerUser.password);
      registerUser.accountIdentifier = invite.accountIdentifier;
      registerUser.userInviteIdentifier = invite.identifier;

      //if this Account has a status of INVITED, we use a different endpoint to create the first AspNetUser.
      const regResult = await AuthApi.CompleteRegistrationForInvitedAccount(
        registerUser,
        null
      );
      if (regResult.message == "") {
        const response = await AuthApi.login({
          Email: registerUser.email,
          Password: registerUser.password,
        });
        if (response.statusCode === 200) {
          Cookie.set("jwtToken", response.data.result, { expires: 1 });
          localStorage.setItem("directory", "");
          navigate("/");
          window.location.reload();
        } else {
          toast.success("Welcome, for security, please log in.");
          navigate("/");
        }
      } else {
        toast.error(regResult.message);
      }
    }
  };

  return (
    <UnAuthorisedLayout>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} lg={5}>
          <Card sx={{ padding: 2 }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <img src={CrownLogo} alt="Crown logo" />
                </Box>

                <Typography
                  variant="h5"
                  style={{ margin: "10px 0", fontWeight: 500 }}
                >
                  Join Crown Downloads
                </Typography>
              </Box>
            </CardContent>
            <CardContent>
              {loading && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Grid>
              )}
              {!loading && (
                <>
                  <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          name="firstname"
                          label="First Name"
                          placeholder="First Name"
                          fullWidth
                          value={signupDetails.firstname}
                          sx={{ marginBottom: 2 }}
                          InputLabelProps={{ shrink: true }}
                          autoComplete={false}
                          required={true}
                        />
                        <TextField
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          name="lastname"
                          label="Last Name"
                          placeholder="Last Name"
                          fullWidth
                          value={signupDetails.lastname}
                          sx={{ marginBottom: 2 }}
                          InputLabelProps={{ shrink: true }}
                          autoComplete={false}
                          required={true}
                        />
                        <TextField
                          type="text"
                          variant="outlined"
                          onChange={handleChange}
                          name="phone"
                          label="Phone"
                          placeholder="Phone"
                          fullWidth
                          value={signupDetails.phone}
                          sx={{ marginBottom: 2 }}
                          InputLabelProps={{ shrink: true }}
                          autoComplete={false}
                          required={true}
                        />
                        <TextField
                          id="email"
                          type="email"
                          variant="outlined"
                          onChange={handleChange}
                          name="email"
                          label="Email"
                          placeholder="Email"
                          fullWidth
                          value={signupDetails.email}
                          sx={{ marginBottom: 2 }}
                          InputLabelProps={{ shrink: true }}
                          autoComplete={false}
                          required={true}
                        />
                        <TextField
                          type="password"
                          variant="outlined"
                          onChange={handleChange}
                          name="password"
                          label="Password"
                          placeholder="Password"
                          fullWidth
                          value={signupDetails.password}
                          sx={{ marginBottom: 2, marginTop: 1 }}
                          InputLabelProps={{ shrink: true }}
                          autoComplete={false}
                          required={true}
                        />
                        <PasswordStrengthBar
                          password={signupDetails.password}
                        />
                        <TextField
                          type="password"
                          variant="outlined"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          fullWidth
                          value={confirmPassword}
                          sx={{ marginBottom: 2, marginTop: 1 }}
                          InputLabelProps={{ shrink: true }}
                          autoComplete={false}
                          required={true}
                        />
                      </FormControl>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "1em",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Complete Registration
                        </Button>
                      </div>
                    </form>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </UnAuthorisedLayout>
  );
};
export default CompleteUserSignup;
